

.header_search {
    padding: 32px 22px;
    position: relative;
    .searchheader_box {
        position: absolute;
        width: 250px;
        right: 8px;
        top: 70px;
       
    }
    .searchheader_box button.btn.btn-primary {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 46px;
        border-radius: 0px 4px 4px 0px;
        padding: 0px;
        width: 45px;
        background-color: #deecf9;
        border-color: #deecf9;
    }
    .searchheader_box .form-control {
        height: 46px;
        padding: 0px 45px 0px 15px;
        border-color: #deecf9;
    }
}