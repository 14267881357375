
.Header_Language {
    width: 108px;
    margin-left: 22px;
    
    select.form-select {
        border: 2px solid #25AAE1;
        color: #25AAE1;
        border-radius: 4px;
        background-image: url(../../Static/arrowdown.svg);
        font-family: 'litera';
        font-weight: 500;
    }
}