
.account_main {
    background-color: #FAFBFC;
    height: 100vh;
    font-family: 'litera';
    .account_innerbody {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .middle_accountcontant {
                width: 100%;
            .logo_account {
                text-align: center;
                margin-bottom: 30px;
            }
            .account_box {
                max-width: 600px;
                width: 100%;
                background-color: #fff;
                margin: 0px auto;
                padding: 50px 100px 30px 100px;
                filter: drop-shadow(-4px 8px 24px rgba(44, 63, 88, 0.02));
                border-radius: 20px;
                h3 {
                    font-size: 28px;
                    line-height: 33px;
                    text-align: center;
                    color: #3F434A;
                    font-family: 'litera';
                    font-weight: 700;
                    margin-bottom: 20px;
                }
                .account_btn {
                    background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
                    border-radius: 4px;
                    width: 100%;
                    font-size: 16px;
                    line-height: 30px;
                    height: 46px;
                    margin-bottom: 30px;
                    margin-top: 10px;
                    font-family: 'litera';
                    font-weight: 500;
                }
                .accountform {
                    label {
                        font-size: 14px;
                        line-height: 17px;
                        color: rgba(0, 0, 0, 0.87);
                        margin-bottom: 10px;
                        font-family: 'litera';
                        font-weight: 700;
                    }
                    input.form-label {
                        background-color: transparent !important;
                        border: 1px solid #E8E9EB;
                        height: 40px;
                        font-family: 'litera';
                        font-weight: 700;
                    }
                    input:focus-visible {
                        outline: none !important;
                    }
                    .mrbtm0 {
                        margin-bottom: 0px;
                        color: rgba(0, 0, 0, 0.84);
                        font-size: 14px;
                        line-height: 17px;
                        font-family: 'litera';
                        font-weight: 700;
                        .CustomLinks {
                            text-decoration: none;
                        }
                    }
                    .cehckboxandforgot {
                        .form-check {   
                            display: inline-block;
                            label {
                                margin-bottom: 0px;
                            }
                        }
                        .CustomLinks {
                            float: right;
                            color: #0078D4;
                            font-size: 14px;
                            line-height: 17px;
                            font-family: "litera";
                            font-weight: 700;
                            text-decoration: none;
                            margin-top: 4px;
                        }
                    }
                    .captcha_login {
                        margin-bottom: 10px;
                        label{
                            color: rgba(0, 0, 0, 0.34);
                            font-size: 14px;
                            line-height: 17px;
                            font-family: "litera";
                            font-weight: 700;
                        }
                        input {
                            background-color: transparent !important;
                            border: 1px solid #E8E9EB;
                            height: 40px;
                            font-family: 'litera';
                            font-weight: 700;
                            padding: 0px 10px;
                            border-radius: 4px;
                            font-size: 14px;
                            width:100%;
                        }
                        .ex_captcha {
                            margin-top: 5px;
                             a#reload_href {
                                color: #4284ec !important;
                                width: 100%;
                                text-align: center !important;
                                display: block;
                                font-size: 15px;
                            }
                        }
                        .errormessgae {
                            font-size: 12px;
                            position: absolute;
                            color: red;
                        }
                    }

                    p.errormessgae {
                        font-size: 12px;
                        color: red;
                        
                    }
                }
            }
        }
    }
}


@media (max-width:767px) {
    .account_main .account_innerbody .middle_accountcontant .account_box {
        padding: 20px 20px;
        width: 90%;
    }
    .account_main .account_innerbody .middle_accountcontant .account_box h3 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .account_main .account_innerbody .middle_accountcontant .account_box .accountform .captcha_login .ex_captcha a#reload_href {
        text-align: left !important;
    }
}