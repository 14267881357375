
.main_TutorialOPE {
    padding: 0px 20px 0px 0px;
    .col-sm-3 {
        width: 300px;
        background: #FAFBFC;
        padding: 0px 0px 0px 28px !important;
    }
    .col-sm-9 {
        width: calc(100% - 300px);
    }
    .righside_contantbar {
        padding: 50px 100px;
        .tab-content {
            min-height: 300px;
        }
        p {
            margin-bottom: 30px;
        }
    }
    .searchtopic_tutorials {
        position: relative;
        padding-right: 16px;
        margin-top: 10px;
        input.form-control {
            padding: 0px 0px 0px 40px;
            height: 40px;
            border: 1px solid #CCCCCC;
            font-size: 14px;
            font-family: 'litera';
            font-weight: 700;
        }
        .search_btn {
            position: absolute;
            top: 0px;
            left: 0px;
            right: auto;
            background-color: transparent;
            border: 0px none;
            padding: 0px 10px;
            height: 40px;
        }
    }
    .leftsidetabs.nav-pills {
        .nav-link {
            color: rgba(0, 0, 0, 0.38);
            font-family: 'litera';
            font-weight: 700;
            font-size: 14px;
        }
        .nav-link.active {
            background-color: #fff;
            color: #0078D4;
            font-family: 'litera';
            font-weight: 700;
            font-size: 14px;
        }
    }

    .NextPrevbtn {
        display: flex;
        justify-content: space-between;
        .iconTabs {
            margin-top: 7px;
        }
        .PrevMain {
            border: 1px solid #ccc;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 15px;
            border-radius: 4px;
            width: 100%;
            margin-right: 10px;
            .tabslistbtns {
                h4 {
                    text-align: right;
                }
                p {
                    text-align: right;
                }
            }
        }
        
        .NextMain {
            border: 1px solid #ccc;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 15px;
            border-radius: 4px;
            width: 100%;
            margin-left: 10px;
        }
        .tabslistbtns {
            h4 {
                color: rgba(0, 0, 0, 0.54);
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0px;
            }
            p {
                color: rgba(0, 0, 0, 0.84);
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0px;
            }
        }
    }
    
    .subheading_tabdetails {
        h3 {
            font-size: 20px;
            line-height: 30px;
        }
    }
    
}


@media (max-width:990px) {
    .main_TutorialOPE {
        padding: 0px 12px 0px 0px;
        .col-sm-3 {
            width: 100%;
            padding: 15px 15px 15px 30px !important;

        }
        .righside_contantbar {
            width: 100%;
            padding: 30px 30px;
            .tab-content {
                min-height: 100px;
            }
        }
         .NextPrevbtn {
            flex-wrap: wrap;
            .PrevMain {
                margin-right: 0px;
                margin-bottom: 10px;
            }
            .NextMain {
                margin-left: 0px;
            }
        }
        .searchtopic_tutorials {
            padding-right: 0px;
        }
    }
}