.FAQSECTION {
    // background-image: url(../../Static/bg-faq.png);
    background-position: 50% 0%;
    background-repeat: no-repeat;
    padding-bottom: 180px;
    position: relative;
}
.FAQSECTION:before {
    content: "";
    -webkit-animation: spinAround 80s linear infinite;
    -moz-animation: spinAround 80s linear infinite;
    animation: spinAround 80s linear infinite;
    background-image: url(../../Static/bg-faq2.svg);
    background-repeat: no-repeat;
    width: 630px;
    background-size: contain;
    height: 600px;
    position: absolute;
    background-position: center;
    z-index: -1;
    margin: 0px auto;
    right: 0px;
    left: 0px;
}

.FAQSECTION:after {
    content: "";
    position: absolute;
    background-image: url(../../Static/ope-arrows.svg);
    width: 60px;
    background-size: 60px;
    height: 110px;
    top: 448px;
    right: 0;
    left: 0;
    margin: 0px auto;
    background-repeat: no-repeat;
    z-index: -1;
}
.faq_main_section {
    max-width: 970px;
    margin: 0px auto;
}
.accordion {
    margin-bottom: 16px;
    .accordion-button:not(.collapsed) {
        color: #000;
        background-color: transparent;
    }
    .accordion-header {
        button.accordion-button {
            font-size: 24px;
            line-height: 34px;
            padding: 20px 20px 20px 60px;
            box-shadow: none;
        }
    }
    .accordion-item:last-of-type .accordion-button.collapsed {
        border-radius: 6px;
    }
    .accordion-item:last-of-type .accordion-collapse {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    .accordion-item:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    .accordion-item {
        background-color: #fff;
        border: 1px solid #C7C7C7;
    }
    .accordion-button::after {
        margin-left: -40px;
        position: absolute;
        transform: rotate(-90deg);
    }
    .accordion-button:not(.collapsed)::after {
        transform: rotate(0deg);
    }
    .accordion-body {
        padding: 0px 20px 20px 60px;
        font-size: 16px;
        line-height: 24px;
        font-family: 'litera';
        font-weight: 400;
        color: rgba(0, 0, 0, 0.84);
    }
}

@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes spinAround {
    from {
        -moz-transform: rotate(0deg)
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@keyframes spinAround {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width:990px) {
    .FAQSECTION {
        padding-bottom: 60px;
        padding-top: 60px;
        background-position: 50% 50%;
        background-size: 60%;
    }
    .accordion {
        .accordion-header {
            button.accordion-button {
                padding: 10px 10px 10px 40px;
                font-size: 18px;
                line-height: 28px;
            }
            .accordion-button::after {
                margin-left: -30px;
            }
        }
        .accordion-body {
            padding: 0px 20px 20px 40px;
            font-size: 15px;
        }
    }
}

@media (max-width:767px) { 
    .FAQSECTION {
        background-position: 50% 20%;
        background-size: 90%;
    }
    .FAQSECTION:before {
        display: none;
    }
    .whatmakesleftimage {
        padding: 50px 0px;
        .topImageWhat {
            top: 0%;
        }
        .bottamImageWhat {
            bottom: 0%;
        }
    }
}