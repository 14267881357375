.Main_dapps {
    color: rgba(0, 0, 0, 0.84);
    font-family: 'litera';
    font-weight: 700;
    .Dapps_table_box {
        background: #FAFBFC;
        border: 1px solid #CCCCCC;
        border-radius: 20px;
        padding: 0px;
        overflow: hidden;
        margin-top: 15px; 
        margin-bottom: 100px;
        .react-bootstrap-table {
            padding: 16px 24px 0px 24px;
            overflow-x: auto;
            table {
                thead {
                    tr {
                        th{
                            padding: 4px 10px;
                            color: rgba(0, 0, 0, 0.54);
                            font-size: 14px;
                            line-height: 30px;
                            font-family: "litera";
                            font-weight: 700;
                            border: 0px none;
                        }
                        // th:nth-child(3) {
                        //     min-width: 210px !important;
                        //     width: 300px !important;
                        // }
                    }
                }
                tbody {
                    tr {
                        vertical-align: middle;
                        td {
                            font-size: 14px;
                            line-height: 30px;
                            font-family: "litera";
                            font-weight: 700;
                            color: rgba(0, 0, 0, 0.84);
                            border: 0px none;
                            padding: 4px 10px;
                            border: 0px none;
                            .item_name {
                                a {
                                    color: rgba(0, 0, 0, 0.84);
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-family: 'litera';
                                    font-weight: 700;
                                    text-decoration: none;
                                }
                            }
                            .Apptype_img {
                                svg {
                                    color: rgba(0, 0, 0, 0.54);
                                    margin-right: 8px;
                                }
                            }
                            .staricon_table {
                                svg {
                                    color: #FF981F;
                                    margin-right: 6px;
                                }
                            }
                        }
                    }
                    tr:nth-of-type(odd)>* {
                        background: #FFFFFF;
                        --bs-table-accent-bg:  #FFFFFF;
                        padding: 4px 10px !important;
                    }
                }
            }
            .table>:not(:first-child) {
                border-top: 0px none;
            }
        }
        .react-bootstrap-table-pagination {
            .react-bootstrap-table-pagination-list.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
                width: 100%;
                padding: 14px 0px;
                border-top: 1px solid #CCC !important;
                .react-bootstrap-table-page-btns-ul {
                    float: none;
                    margin-top: 0!important;
                    justify-content: center;
                    margin-bottom: 0px;
                    li.page-item {
                        border-radius: 4px;
                        margin: 0px 4px;
                        .page-link {
                            color: rgba(0, 0, 0, 0.54);
                            border: 1px solid #EEEEEE;
                            height: 34px;
                            padding: 0px 0px;
                            line-height: 30px;
                            text-align: center;
                            width: 34px;
                            border-radius: 4px;
                        }
                        .page-link:focus {
                            box-shadow: none;
                        }
                    }
                    .page-item.active {
                        .page-link {
                            padding: 0px;
                            height: 34px;
                            text-align: center;
                            line-height: 32px;
                            border: 1px solid #0078D4;
                            background-color: transparent;
                            color: #0078D4;
                        }
                    }
                    .page-item:not(:first-child) .page-link {
                        margin-left: 0px;
                    }
                }
            }
            .col-md-6.col-xs-6.col-sm-6.col-lg-6:first-child {
                display: none;
            }
            
        }
        .Dappstopbar {
            background-color: #fff;
        }
        .table-responsive {
            padding: 16px 24px 0px 24px;
            .table>:not(:first-child) {
                border-top: 0px none;
            }
            table {
                margin-bottom: 0px;
                thead {
                    tr {
                        th {
                            color: rgba(0, 0, 0, 0.54);
                            font-size: 14px;
                            line-height: 30px;
                            font-family: 'litera';
                            font-weight: 700;
                            border: 0px none;
                        }
                        th:nth-child(1) {
                            width: 30px;
                        }
                        th:nth-child(2) {
                            width: 50px;
                        }
                        th:nth-child(3) {
                            width: 50%;
                        }
                        th:nth-child(4) {
                            width: 100px;
                        }
                        th:nth-child(5) {
                            width: 150px;
                        }
                        th:nth-child(6) {
                            width:150px;
                        }
                    }
                }
                tbody {
                    tr {
                        vertical-align: middle;
                        td {
                            padding: 10px 10px !important;
                            font-size: 14px;
                            line-height: 30px;
                            font-family: 'litera';
                            font-weight: 700;
                            color: rgba(0, 0, 0, 0.84);
                            border: 0px none;
                        }
                        .staricon_table {
                            svg {
                                color: #FF981F;
                                margin-right: 6px;
                            }
                        }
                        .apptype_icon {
                            svg {
                                color: rgba(0, 0, 0, 0.54);
                                margin-right: 10px;
                            }
                        }
                    }
                    tr:nth-of-type(odd)>* {
                        background: #FFFFFF;
                        --bs-table-accent-bg:  #FFFFFF;
                        padding: 4px 10px !important;
                    }
                }
            }
            .table-hover>tbody>tr:hover>* {
                --bs-table-accent-bg : #f7f7f7  !important;
            }
        }

        .pagination_table {
            display: flex;
            justify-content: center;
            border-top: 1px solid #ccc;
            margin-bottom: 0px !important;
            padding-top: 12px;
            padding-bottom: 12px;
            background-color: #fff;
            .pagination {
                margin: 0px;
                li.page-item {
                    border-radius: 4px;
                    margin: 0px 4px;
                    .page-link {
                        color: rgba(0, 0, 0, 0.54);
                        border: 1px solid #EEEEEE;
                        height: 34px;
                        padding: 0px 0px;
                        line-height: 32px;
                        text-align: center;
                        width: 34px;
                        border-radius: 4px;
                    }
                }
                .page-item.active {
                    .page-link {
                        padding: 0px;
                        height: 34px;
                        text-align: center;
                        line-height: 34px;
                        border: 1px solid #0078D4;
                        background-color: transparent;
                        color: #0078D4;
                    }
                }
                .page-item:not(:first-child) .page-link {
                    margin-left: 0px;
                }
            }
            
        }

    }

    .mainSection_dapps {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .btn_blue {
            background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
            border-radius: 4px;
            height: 46px;
            line-height: 46px;
            padding: 0px 16px;
            border: 0px;
            font-size: 16px;
            font-family: "litera";
            font-weight: 500;
        }
    }
    
    .Dappstopbar {
        border-bottom: 1px solid #ccc;
        background-color: #fff;
        ul {
            margin-bottom: 0px;
            list-style: none;
            padding: 0px;
            li {
                display: inline-block;
                padding: 10px 24px;
                font-family: 'litera';
                font-weight: 500;
                color: rgba(0, 0, 0, 0.54);
            }
            li.active {
                color: #0078D4;
                border-bottom: 2px solid #0078D4;
            }
        }
    }
    
}

.drappsdetails_Main {
    margin-top: 30px;
    padding-bottom: 100px;
    .drappsdetails_inner {
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        .detailspage_image {
            margin-right: 28px;
            width: 100px;
        }
        .drapps_details_titles {
            h3 {
                margin: 0px;
                font-size: 30px;
                line-height: 30px;
                font-family: "litera";
                font-weight: 700;
                color: #000000;
                span {
                    font-size: 16px;
                    line-height: 30px;
                    color: #000000;
                    font-family: 'litera';
                    font-weight: 700;
                }
            }
            .starIcons {
                .staricon_table {
                    svg {
                        color: #FF981F;
                        margin-right: 6px;
                    }
                    p {
                        display: inline-block;
                        margin: 0px;
                        color: rgba(0, 0, 0, 0.84);
                        font-size: 16px;
                        position: relative;
                        top: 3px;
                        font-family: 'litera';
                        font-weight: 500;
                        margin-left: 2px;
                        span {
                            color: rgba(0, 0, 0, 0.54);
                            font-size: 14px;
                            margin-left: 12px;
                            position: relative;
                            top: -1px;
                            font-family: 'litera';
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
    .Vieapp {
        text-align: right;
        margin-bottom: 80px;
    }
    .slider_details {
        .carousel-control-prev {
            display: none;
        }
        .carousel-control-next {
            display: none;
        }
        .carousel-indicators {
            bottom: -38px;
            button {
                background-color: #C4C4C4;
                width: 8px;
                height: 8px;
                opacity: 1;
                border-radius: 8px !important;
                background-clip: initial;
                box-sizing: inherit;
                padding: 0px;
                border: 0px none;
            }
            button.active {
                background-color: #0078D4;
            }
        }
    }
    .Review_drappsdetails {
        margin-top: 100px;
        .title_review_details {
            h3 {
                font-size: 30px;
                line-height: 30px;
                color: #000000;
            }
            span{
                font-size: 16px;
                line-height: 30px;
                color: rgba(0, 0, 0, 0.54);
                font-family: 'litera';
                font-weight: 500;
            }
            span.rating_points {
                margin: 0px 25px;
            }
        }
        .ReviewList_drapps {
            background: #FAFBFC;
            border-radius: 20px;
            margin-top: 20px;
            ul {
                list-style: none;
                padding: 0px;
                li {
                    border-bottom: 1px solid #ccc;
                    .Review_Mainbody_drapps {
                        display: flex;
                        padding: 20px 20px;
                        .Review_Firstname_drapps {
                            margin-right: 20px;
                            p {
                                width: 32px;
                                height: 32px;
                                background: #DEECF9;
                                line-height: 32px;
                                font-size: 14px;
                                color: #000;
                                text-align: center;
                                border-radius: 50%;
                                margin-bottom: 0px;
                            }
                        }
                        .Review_descriptionRating_drapps {
                            .Review_Rating_drapps {
                                h5 {
                                    color: rgba(0, 0, 0, 0.84);
                                    font-size: 16px;
                                    line-height: 30px;
                                    font-family: 'litera';
                                    font-weight: 700;
                                    .star_review_rating {
                                        margin-left: 10px;
                                        position: relative;
                                        top: -2px;
                                        svg {
                                            color: #FF981F;
                                            margin-right: -3px;
                                        }
                                    }
                                }

                            }
                            .Review_description_drapps {
                                p {
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: rgba(0, 0, 0, 0.84);
                                    font-family: 'litera';
                                    font-weight: 700;
                                    margin-bottom: 0px;
                                }
                            }
                        }
                        .Review_date_drapps {
                            width: 120px;
                            text-align: right;
                            p {
                                color: rgba(0, 0, 0, 0.38);
                                font-size: 14px;
                                line-height: 30px;
                                margin: 0px;
                                font-family: 'litera';
                                font-weight: 700;
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: 0px none;
                    }
                }
                
            }
        }
    }
}

.publish_dapp_model {
    .gray_color {
        color: rgba(0, 0, 0, 0.38);
    }
    .modal-dialog {
        max-width: 600px;
    }
    .modal-content {
        border-radius: 20px !important;
        border: 0px none;
    }
    .modal-body {
        padding: 30px;
    }
    label {
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 10px;
        font-family: "litera";
        font-weight: 700;
        span.red_star {
            color: #f30202;
            font-size: 16px;
            position: relative;
            top: 2px;
            line-height: 10px;
        }
    }
    label.iciELI {
        border: 1px solid #E8E9EB;
        min-height: 160px;
        color: #0078D4;
        font-size: 14px;
        line-height: 16px;
        svg {
            display: none;
        }
        .ebdQkY {
            display: block;
            text-align: center;
            color: #0078D4;
            font-size: 14px;
            line-height: 16px;
            font-family: 'litera';
                font-weight: 500;
            span.file-types {
                display: block;
                margin: 0px auto;
                color: #0078D4;
                font-size: 14px;
                line-height: 16px;
                font-family: 'litera';
                font-weight: 500;
                max-width: 100%;
            }
            .kCudqJ {
                color: #0078D4;
                font-size: 14px;
                line-height: 16px;
                font-family: 'litera';
                font-weight: 500;
                span {
                    color: #0078D4;
                font-size: 14px;
                line-height: 16px;
                font-family: 'litera';
                font-weight: 500;
                }
            }
        }
    }
    .LogoUpload_leftside {
        input.form-control {
            height: 160px;
            position: relative;
        }
        input.form-control:after {
            width: 100%;
            position: absolute;
            height: 100%;
            background-color: #fff;
            left: 0px;
            top: 0px;
            content: "Upload Logo";
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0078D4;
            font-size: 14px;
            line-height: 16px;
            font-family: "litera";
            font-weight: 500;
        }
    }
    
    input.form-control {
        border: 1px solid #E8E9EB;
        height: 40px;
        font-family: "litera";
        font-weight: 700;
        font-size: 14px;
    }
    textarea.form-control {
        border: 1px solid #E8E9EB;
        font-family: "litera";
        font-weight: 700;
        font-size: 14px;
    }
    .btn-cancel {
        background-color: transparent;
        color: #0078D4;
        border: 0px none;
    }
    .btn-publish {
        background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
        border-radius: 4px;
        font-size: 16px;
        font-family: "litera";
        font-weight: 500;
        height: 46px;
    }
}


// Table CSS
.item_img img {
    width: 100%;
}   
// Table CSS end



  .publish_dapp_model ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .publish_dapp_model :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .publish_dapp_model ::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }

@media (max-width:767px) {
    .Main_dapps .Dapps_table_box .react-bootstrap-table table thead tr th {
        width: 250px;
    }
    .Main_dapps .Dapps_table_box {
        width: 92%;
        margin: 20px auto 0px;
    }
    .publish_dapp_model label.iciELI {
        min-width: 100%;
        height: 160px;
    }
    .publish_dapp_model {
        .Upload_logo_image {
            label {
                min-height: 34px;
            }
        }
    }
    
}