
.account_main_reg {
    background-color: #FAFBFC;
    padding-top: 60px;
    padding-bottom: 60px;
    font-family: 'litera';
    // height: 100vh;
    .account_innerbody_reg {
        margin: 0px auto;
        .middle_accountcontant_reg {
                width: 100%;
            .logo_account {
                text-align: center;
                margin-bottom: 30px;
                
            }
            .account_box {
                max-width: 600px;
                width: 100%;
                background-color: #fff;
                margin: 0px auto;
                padding: 60px 100px 30px 100px;
                filter: drop-shadow(-4px 8px 24px rgba(44, 63, 88, 0.02));
                border-radius: 20px;
                h3 {
                    font-size: 28px;
                    line-height: 33px;
                    text-align: center;
                    color: #3F434A;
                    font-family: 'litera';
                    font-weight: 700;
                    margin-bottom: 40px;
                }
                .account_btn {
                    background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
                    border-radius: 4px;
                    width: 100%;
                    font-size: 16px;
                    line-height: 30px;
                    height: 46px;
                    margin-bottom: 30px;
                    margin-top: 0px;
                    font-family: 'litera';
                    font-weight: 500;
                }
                .accountform {
                    .verifiction_group {
                        label {
                            color: rgba(0, 0, 0, 0.34);
                        }
                        .verify_btn {
                            background: #FFFFFF;
                            border: 1px solid #CCCCCC;
                            height: 40px;
                            border-radius: 4px;
                            font-size: 16px;
                            color: #0078D4;
                            font-family: 'litera';
                            font-weight: 500;
                            margin-top: 10px;
                        }
                    }
                    label {
                        font-size: 14px;
                        line-height: 17px;
                        color: rgba(0, 0, 0, 0.87);
                        margin-bottom: 10px;
                        font-family: 'litera';
                        font-weight: 700;
                    }
                    input.form-control {
                        background-color: transparent !important;
                        border: 1px solid #E8E9EB;
                        height: 40px;
                        font-family: 'litera';
                        font-weight: 700;
                        font-size: 14px;
                    }
                    textarea.form-control {
                        background-color: transparent !important;
                        border: 1px solid #E8E9EB;
                        font-family: 'litera';
                        font-weight: 700;
                        font-size: 14px;
                    }
                    .form-select {
                        background-color: transparent !important;
                        border: 1px solid #E8E9EB;
                        height: 40px;
                        font-family: 'litera';
                        font-weight: 700;
                        font-size: 14px;
                    }
                    .mrbtm0 {
                        margin-bottom: 0px;
                        color: #8A9099;
                        font-size: 14px;
                        line-height: 17px;
                        .CustomLinks {
                            text-decoration: none;
                        }
                    }
                    .cehckboxandforgot {
                        .form-check {   
                            display: inline-block;
                            label {
                                margin-bottom: 0px;
                            }
                        }
                        .CustomLinks {
                            float: right;
                            color: #0078D4;
                            font-size: 14px;
                            line-height: 17px;
                            font-family: "litera";
                            font-weight: 700;
                            text-decoration: none;
                            margin-top: 4px;
                        }
                    }
                    .registraion_submit_information {
                        p {
                            font-size: 14px;
                            line-height: 20px;
                            color: rgba(0, 0, 0, 0.84);
                            font-family: 'litera';
                            font-weight: 700;
                        }
                    }
                    .info_form {
                        color: rgba(0, 0, 0, 0.54);
                        font-size: 14px;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
}


.accountform ::-webkit-input-placeholder { 
    color: rgba(0, 0, 0, 0.38);
}
  
.accountform :-ms-input-placeholder { 
    color: rgba(0, 0, 0, 0.38);
}
  
.accountform ::placeholder {
    color: rgba(0, 0, 0, 0.38);
}


@media (max-width:767px) {
    .account_main_reg .account_innerbody_reg .middle_accountcontant_reg .account_box {
        padding: 20px;
        width: 90%;
    }
    .account_main_reg {
        padding-top: 30px;
    padding-bottom: 30px;
    }
    .account_main_reg .account_innerbody_reg .middle_accountcontant_reg .account_box .accountform .verifiction_group .verify_btn {
        font-size: 14px;
    }
    .account_main_reg .account_innerbody_reg .middle_accountcontant_reg .account_box .account_btn {
        font-size: 14px;
    }
}
@media (max-width:374px) {
    .account_main_reg .account_innerbody_reg .middle_accountcontant_reg .account_box .account_btn {
        font-size: 11px;
    }
}