
.DetailSection {
    padding: 80px 0px;
    display: flex;
    align-items: center;
    .align-right {
        text-align: right;
    }
    .align_item_center {
        align-items: center;
        display: flex;
    }
    .leftside_contant {
        h2 {
            font-size: 40px;
            line-height: 47px;
            font-family: 'litera';
            font-weight: 700;
            margin-bottom: 20px;
        }
        p {
            font-family: 'litera';
            font-weight: 400;
            color: rgba(0, 0, 0, 0.84);
            margin-bottom: 40px;
            font-size: 20px;
            line-height: 30px;
        }
        .btn_bsn_blue {
            background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
            border-radius: 4px;
            height: 46px;
            line-height: 46px;
            padding: 0px 16px;
            border: 0px;
            font-size: 16px;
            font-family: 'litera';
            font-weight: 700;
            img {
                margin-left: 12px;
            }
            &:hover {
                box-shadow: 0px 2px 2px rgb(55 61 117);
            }
        }
        ul {
            padding: 0;
            max-height: 260px;
            position: relative;
            :before {
                position: absolute;
                content: "";
                background-image: url(../../Static/faded_bg.png);
            }
            ol {
                padding: 0;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
                margin-bottom: 20px;
                span {
                    width: 48px;
                    height: 48px;
                    display: inline-block;
                    border: 1px solid #2B88D8;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 48px;
                    margin-right: 20px;
                    color: #0078D4;
                }
            }
        }
        ul:before {
            position: absolute;
            content: "";
            background-image: url(../../Static/faded_bg.png);
            bottom: -20px;
            width: 100%;
            height: 67px;
            z-index: 9;
            background-size: cover;
        }
    }
    .imageRightsideDetail {
        -webkit-animation: moverleftright 2s infinite alternate;
        animation: moverleftright 2s infinite alternate;
    }
}

.Center_heading {
    h2 {
        font-size: 40px;
        line-height: 47px;
        font-family: "litera";
        font-weight: 700;
        margin-bottom: 20px;
    }
    p{
        font-size: 20px;
        line-height: 30px;
        font-family: "litera";
        font-weight: 400;
        color: rgba(0, 0, 0, 0.84);
        max-width: 872px;
        margin: 0px auto 40px;
    }
}

@-webkit-keyframes moverleftright {
    0% { transform: translateX(0); }
    100% { transform: translateX(-20px); }
}
@keyframes moverleftright {
    0% { transform: translateX(0); }
    100% { transform: translateX(-20px); }
}

@media (max-width:990px) {
    .imageRightsideDetail {
        animation: none !important;
        margin-top: 40px;
        img {
            width: 100%;
        }
    }
    .DetailSection {
        padding: 40px 0px;
        .leftside_contant {
            h2 {
                font-size: 28px;
                line-height: 36px;
                margin-bottom: 20px;
            }
             p {
                font-size: 18px;
                line-height: 26px;
            }
            ul {
                overflow: hidden;
                ol {
                    display: flex;
                    align-items: center;
                    span {
                        flex: 0 0 48px;
                    }
                }
            }
        }
    }
    .leftside_contant {
        h2 {
            font-size: 30px;
            line-height: 36px;
        }
    }
    .Center_heading {
        h2 {
            font-size: 30px;
                line-height: 36px;
        }
        p {
            font-size: 18px;
            line-height: 26px;
        }
    }
}


@media (min-width:990px) and (max-width:1024px) {
    .DetailSection {
        padding: 40px 0px;
        .leftside_contant {
            ul {
                overflow: hidden;
                ol {
                    display: flex;
                    align-items: center;
                    span {
                        flex: 0 0 48px;
                    }
                }
            }
        }
    }
}
