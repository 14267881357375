@font-face {
    font-family: 'litera';
    src: url('./Static/fonts/litera-regular.eot') format('eot'),
         url('./Static/fonts/litera-regular.woff2') format('woff2'),
         url('./Static/fonts/litera-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'litera';
    src: url('./Static/fonts/litera-heavy.eot') format('eot'),
         url('./Static/fonts/litera-heavy.woff2') format('woff2'),
         url('./Static/fonts/litera-heavy.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'litera';
    src: url('./Static/fonts/litera-bold.eot') format('eot'),
         url('./Static/fonts/litera-bold.woff2') format('woff2'),
         url('./Static/fonts/litera-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4, h5 {
    font-family: 'litera';
    font-weight: 700;
}

body {
    font-family: 'litera';
    font-weight: 400;
}