
.whatope_main {
    background-image: url(../../Static/bg-whatope.png);
    background-size: cover;
    align-items: center;
    display: flex;
    position: relative;
    padding: 20% 0%;
    z-index: 0;
    .align_item_center {
        align-items: center;
        display: flex;
    }
    &:before {
        content: "";
        position: absolute;
        background-image: url(../../Static/ope-arrows.svg);
        width: 187px;
        height: 330px;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0px auto;
        background-repeat: no-repeat;
        // -webkit-animation: mover 1s infinite alternate;
        // animation: mover .5s infinite alternate;
    }
    &:after {
        content: "";
        background-image: url(../../Static/WhatisOPEFrame.svg);
        position: absolute;
        right: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        animation: mover 2.5s infinite alternate;
        background-position: left center;
        z-index: -1;
    }
    
    .background_image_ope {
        position: absolute;
        width: 100%;
        z-index: -1;
        img {
            width: 100%;
        }
    }
    .leftcontant_whatope {
        h2 {
            color: #fff;
            font-size: 40px;
            line-height: 30px;
            margin-bottom: 20px;
        }
        p {
            color: #fff;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 40px;
        }
        .btn_bsn_white {
            color: #0d6efd;
            background-color: #fff;
            border-color: #fff;
            border-radius: 4px;
            height: 46px;
            line-height: 46px;
            padding: 0px 16px;
            border: 0px;
            font-size: 16px;
            font-family: "litera";
            font-weight: 700;
            &:hover {
                background-color: rgb(255 255 255 / 90%);
            }
        }
    }
}

@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

@media (max-width:990px){
    .rightsideimage_whatope {
        margin-top: 40px;
        img {
            width: 100%;
        }
    }
    .whatope_main:before {
        display: none;
    }
    .whatope_main {
        .leftcontant_whatope {
            h2 {
                font-size: 28px;
                line-height: 36px;
            }
            p {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
}
@media (min-width:990px) and (max-width:1024px) {
    .rightsideimage_whatope {
        img {
            width: 100%;
        }
    }
}