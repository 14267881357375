.bsn_header {
    .bsn_navbar {
        padding: 0;
        border-bottom: 1px solid #ccc;
        .ml-auto {
            margin-left: auto;
            font-family: 'litera';
            font-weight: 500;
        }
        .headerlinks {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.54);
            font-family: 'litera';
            padding: 34px 22px !important;
            text-decoration: none;
            &:hover {
                color: rgba(0, 0, 0, 1);
            }
        }
        .active {
            color: #0078D4 !important;
            border-bottom: 2px solid #0078D4;
        }
        .dropdown {
            .nav-link {
                padding: 34px 22px !important;
                font-size: 16px;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.54) !important;
                &:hover {
                    color: rgba(0, 0, 0, 1) !important;
                }
            }
            .dropdown-toggle::after {
                border:0px none;
                background-image: url(../../Static/dropdownarrow.svg);
                background-size: 16px;
                margin-left: 10px;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                position: relative;
                top: 10px;
                margin-top: -6px;
            }
        }
        .active.dropdown {
            .nav-link {
                color: #0078D4 !important;
            }
        }
        .dropdown-menu {
            min-width: 240px;
            border-radius: 0px 0px 10px 10px;
            background-color: #ffffff;
            padding: 0px;
            box-shadow: 0px 4px 8px 0px rgb(223 223 223 / 40%);
            margin-top: -1px;
            border: 1px solid #f3f3f3;
            a {
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #037cd9c2;
                border-bottom: 1px solid #ededed;
                padding: 12px 20px;
                display: block;
                text-decoration: none;
                &:hover {
                    background-color: #f9f9f9e0;
                    color: #037cd9;
                }
            }
        }
    }
}

@media (max-width:767px) {
    
    .bsn_header .bsn_navbar .headerlinks {
        padding: 15px 20px 15px 0px !important;
        width: fit-content !important;
    }
    .bsn_header .bsn_navbar .ml-auto {
        border-bottom: 1px solid #ccc !important;
        padding-bottom: 10px !important;
        margin-bottom: 20px !important;
    }
    .header_search {
        padding: 10px 0px !important;
        display: inline-block;
        width: 50%;
        text-align: right;
        padding-right: 30px !important;
        margin-bottom: 20px !important;
    }
    .Header_Language {
        display: inline-block;
        width: 50% !important;
        margin-left: 0px !important;
    }
    .navbar-light .navbar-toggler {
        padding: 0px 0px;
        height: 55px;
        width: 50px;
        border: 1px solid #deecf9;
        background-color: #deecf9;
    }
    .navbar-light .navbar-toggler:focus {
        outline: none;
        box-shadow: none;
    }
    .bsn_header .bsn_navbar .dropdown .nav-link {
        padding: 15px 20px 15px 0px !important;
    }
    .bsn_header .bsn_navbar .dropdown .dropdown-toggle::after {
        right: 0px;
        position: absolute;
        top: 26px;
    }
}

@media (min-width:990px) and (max-width:1024px) {
    .bsn_header .bsn_navbar .headerlinks {
        padding: 34px 15px !important;
        font-size: 15px;
    }
    .bsn_header .bsn_navbar .dropdown .nav-link {
        padding: 34px 15px !important;
        font-size: 15px;
    }
 }