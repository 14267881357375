
.FAQpage_main {
    margin-top: 30px;
    padding-bottom: 100px;
    .FAQpageBody {
        background: #FAFBFC;
        border: 1px solid #CCCCCC;
        border-radius: 20px;
        overflow: hidden;
        margin-top: 10px;
        div.tabs_faq_bodypart {
            padding: 60px;
            .accordion {
                max-width: 970px;
                margin: 0px auto 16px;
            }
        }
        .tabs_faq {
            justify-content: center;
            button {
                color: rgba(0, 0, 0, 0.54);
                font-size: 16px;
                line-height: 30px;
                font-family: 'litera';
                font-weight: 500;
                padding: 0px 24px;
                height: 54px;
            }
            button.active {
                background-color: transparent;
                border: 0px none !important;
                border-bottom: 1px solid #0078D4 !important;
                color: #0078D4;
            }
            .nav-link:hover {
                border: 1px solid #fafbfc;
                border-bottom: 1px solid #0078D4;
                color: #0078D4;
            }
        }
    }
   
}

@media (max-width:990px) {
    .FAQpage_main {
        .FAQpageBody {
            .tabs_faq button {
                font-size: 15px;
                padding: 0px 15px;
            }
            div.tabs_faq_bodypart {
                padding: 15px;
            }
        }
    }
}